import cn from 'classnames';
import { largeProfilePhoto } from 'Consts/defintions';
import { isZonesRoute } from 'Consts/routes';
import { Mac, Person, ZoneDevice } from 'Consts/types';
import ShieldSettings from 'Pages/Settings/Left/Components/Shield settings';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as actions from 'State/actions';
import useDevices from 'State/hooks/useDevices';
import useLocations from 'State/hooks/useLocations';
import usePersonSecurityPolicy from 'State/hooks/usePersonSecurityPolicy';
import { AppDispatch } from 'State/store';
import { getConfigurationFromDomain } from 'subDomainConfiguration';
import Carousel from 'UI/Components/Carousel/Carousel';
import InputListItem from 'UI/Components/Lists/List input';
import Button, { BUTTON_THEMES } from 'UI/Elements/Button';
import Card from 'UI/Elements/Card';
import { IconNames } from 'UI/Elements/Icon';
import { SidePanelContentWrapper, SimpleSidePanel } from 'UI/Layout/Side panel';
import { useSidepanel } from 'Utils/hooks/useSidepanel';
import styles from './style.module.css';

const BELL = 'bell';

type AddEmployeeSidePanelProps = {
  person?: Person;
  selectedDevices?: ZoneDevice[];
};
const AddEmployeeSidePanel: FunctionComponent<AddEmployeeSidePanelProps> = ({
  selectedDevices,
  person,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const personNickName = person?.nickname!;
  const [nickName, setNickName] = useState('');
  const [error, setError] = useState({ nickName: '' });

  const { closeSidepanel } = useSidepanel();
  const [assignedDevicesList, setAssignedDevicesList] = useState<Mac[]>([]);
  const { activeLocation } = useLocations();
  const { personSecurityPolicy, personShieldSettings } =
    usePersonSecurityPolicy(person?.id!);
  const environment = getConfigurationFromDomain();

  const handleChangePersonName = useCallback((value: string) => {
    setNickName(value);
  }, []);
  const { allDevices } = useDevices();
  const routerLocation = useLocation();
  const currentRoute = routerLocation.pathname;

  useEffect(() => {
    if (selectedDevices?.length) {
      selectedDevices.forEach((d) => {
        assignedDevicesList.push(d.mac);
      });
    }
    if (allDevices?.data && person) {
      allDevices.data.forEach((device) => {
        if (device.personId === person?.id)
          assignedDevicesList.push(device.mac);
      });
    }
    setAssignedDevicesList([...assignedDevicesList]);
  }, [actions.device]);

  useEffect(() => {
    if (!personNickName) {
      setNickName('');
    }
    setNickName(personNickName);
  }, [person]);

  const carouselItems = useMemo(() => {
    return Object.entries(largeProfilePhoto).map((item, i) => {
      return {
        id: item[0],
        name: item[1],
      };
    });
  }, []);

  const [index, setIndex] = useState(
    person?.imageId
      ? carouselItems.findIndex((item) => person?.imageId === item?.id)
      : 0
  );

  const imageId = useMemo(() => {
    if (index === -1) {
      setIndex(1);
      return carouselItems[1].id;
    } else {
      return carouselItems[index].id;
    }
  }, [carouselItems, index]);

  const handlePrevious = () => {
    setIndex(index === 0 ? carouselItems.length - 1 : index - 1);
  };

  const handleNext = () => {
    setIndex(index === carouselItems.length - 1 ? 0 : index + 1);
  };

  const handleSubmit = useCallback(async () => {
    if (!person) {
      if (!nickName || !imageId) {
        setError((prev) => ({
          ...prev,
          nickName: t('onboarding.errorEmptyField'),
        }));
        return;
      }

      if (assignedDevicesList && assignedDevicesList.length > 0) {
        dispatch(
          actions.employees.addEmployee(nickName, imageId, assignedDevicesList)
        );
      } else {
        dispatch(actions.employees.addEmployee(nickName, imageId));
      }

      dispatch(
        actions.ui.miniBanner.notify({
          label: t('addEmployee.addEmployeeBanner'),
          state: 'set',
        })
      );
    } else if (person) {
      if (!nickName) {
        setError((prev) => ({
          ...prev,
          nickName: t('onboarding.errorEmptyField'),
        }));
        return;
      }
      if (assignedDevicesList.length && !person.primaryDevice) {
        dispatch(
          actions.employee.assignPrimaryDeviceToEmployee(
            person.id,
            assignedDevicesList[0]
          )
        );
      }
      dispatch(
        actions.employee.updateEmployee(
          person.id,
          nickName,
          imageId,
          assignedDevicesList
        )
      );
    }
    closeSidepanel();
  }, [person, nickName, imageId, assignedDevicesList, dispatch, t]);

  return (
    <SimpleSidePanel
      title={!person ? t('common.addEmployee') : t('employeeZone.editProfile')}
    >
      <SidePanelContentWrapper className={styles.sidePanelContentWrapper}>
        <Card noBottomPadding>
          <div className={styles.cardWrapper}>
            <Carousel
              carouselItems={carouselItems}
              selectedIndex={index}
              onPrev={() => handlePrevious()}
              onNext={() => handleNext()}
            />
            <div className={styles.listInputConatiner}>
              <InputListItem
                className={styles.listInput}
                prefixIcon={IconNames.Person}
                smallLabel={t('employeeZone.personName') as string}
                value={nickName ? nickName : ''}
                paragraph={error.nickName ? error.nickName : ''}
                onChange={handleChangePersonName}
                hasError={Boolean(error.nickName)}
              />
            </div>
          </div>
        </Card>
        {person && (
          <ShieldSettings
            locationName={activeLocation.data?.name || ''}
            personSecurityPolicyData={personSecurityPolicy}
            shieldSettingsData={personShieldSettings}
            security="person"
            showShieldText={false}
            person={person}
          />
        )}
      </SidePanelContentWrapper>
      <div
        className={
          environment.id === BELL
            ? styles.addEmployeeDoneBtnContainerBell
            : styles.addEmployeeDoneBtnContainer
        }
      >
        <Button
          className={
            environment.id === BELL
              ? styles.addEmployeeDoneBtnBell
              : cn(styles.addEmployeeDoneBtn, {
                  [styles.zonesNavBarButton]: isZonesRoute(currentRoute),
                })
          }
          theme={BUTTON_THEMES.black}
          label={t('common.done')}
          onClick={() => handleSubmit()}
        />
      </div>
    </SimpleSidePanel>
  );
};

export default AddEmployeeSidePanel;
