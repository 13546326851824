import { isToday } from 'date-fns';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';

import { ROUTES } from 'Consts/routes';
import useSecurityEvents from 'State/hooks/useSecurityEvents';

import Background from 'UI/Layout/Background';
import Columns from 'UI/Layout/Columns';
import Page from 'UI/Layout/Page';
import PageHeader from 'UI/Layout/Page header';

import EmployeesAtWork from 'UI/Reusable/Employees at work';
import Guests from 'UI/Reusable/Guests';

import ShieldGraphCard from 'UI/Reusable/Shield graph';
import { FilterNamePlusAll } from 'UI/Reusable/Shield graph/utils';

import useLayoutColumns from 'Utils/hooks/useLayout';

import {
  APP_MONITORING_FEATURE_ANNOUNCEMENT_SHOWN_TIMES,
  BUSINESS_INFO_FEATURE_ANNOUNCEMENT_SHOWN_TIMES,
  EMPLOYEE_LOGIN_FEATURE_ANNOUNCEMENT_SHOWN_TIMES,
} from 'Consts/defintions';
import { OpenTriggerEventType } from 'Consts/types';
import { useDispatch } from 'react-redux';
import * as actions from 'State/actions';
import useCustomerSupportConfigurations from 'State/hooks/useCustomerSupportConfigurations';
import useEmployeeLoginDatabase from 'State/hooks/useEmployeeLoginDatabase';
import useLocationCapabilities from 'State/hooks/useLocationCapabilities';
import useLocations from 'State/hooks/useLocations';
import useLocationSummary from 'State/hooks/useLocationSummary';
import useNetworkAccess from 'State/hooks/useNetworkAccess';
import useSecurityPolicy from 'State/hooks/useSecurityPolicy';
import { AppDispatch } from 'State/store';
import { isPureDevDomain } from 'subDomainConfiguration';
import { IconNames } from 'UI/Elements/Icon';
import Modal from 'UI/Elements/Modals';
import AddEmployeeSidePanel from 'UI/Reusable/AddEmployee/AddEmployee';
import { useSidepanel } from 'Utils/hooks/useSidepanel';
import { useTrackEvent } from '../../trackingAnalytics/hooks/useTrackEvent';
import { MixPanelEvents } from '../../trackingAnalytics/mixPanelEvents';
import { AvailableScreens } from '../../trackingAnalytics/types';
import FeatureAnnouncement from './Components/FeatureAnnouncement';
import WlanCard from './Components/Wlan';

const Home: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [columns, ref] = useLayoutColumns();
  const trackEvent = useTrackEvent();
  const { setContent } = useSidepanel();
  const { data: securityEvents, isLoading, errorMessage } = useSecurityEvents();
  const [activeFilter, setActiveFilter] = useState<FilterNamePlusAll>('all');
  useLocationSummary();

  const [whichModalIsOpen, setWhichModalIsOpen] = useState<string | null>(null);
  const { data: customerSupportConfigurations } =
    useCustomerSupportConfigurations();

  const { shieldSettings, locationSecurityAppliesToAllDevices } =
    useSecurityPolicy();

  const { data: locationCapabilitiesData } = useLocationCapabilities();
  const { data: employeeLoginDatabaseData } = useEmployeeLoginDatabase();
  const employeeLoginToggleIsEnabled =
    employeeLoginDatabaseData?.databaseData.enabled;
  useNetworkAccess();

  const employeeLoginFeatureIsSupported = useMemo(() => {
    return (
      !!customerSupportConfigurations?.customerFeatureEnablement
        .employeeOnboardingCaptivePortalEnabled &&
      !!locationCapabilitiesData?.state.capabilities?.captivePortalV2
    );
  }, [
    locationCapabilitiesData,
    customerSupportConfigurations,
  ]);

  useEffect(() => {

    if (
      customerSupportConfigurations?.customerFeatureEnablement &&
      locationSecurityAppliesToAllDevices.data.appTime !== undefined &&
      shieldSettings.data &&
      locationCapabilitiesData &&
      employeeLoginDatabaseData?.databaseData
    ) {
      // Local storage keys to determine last shown time of any feature announcement
      const storedValueAM = localStorage.getItem(
        APP_MONITORING_FEATURE_ANNOUNCEMENT_SHOWN_TIMES
      );
      const appMonitoringFeatureShownTimes =
        storedValueAM && JSON.parse(storedValueAM) instanceof Array
          ? JSON.parse(storedValueAM)
          : [];

      const storedValueBI = localStorage.getItem(
        BUSINESS_INFO_FEATURE_ANNOUNCEMENT_SHOWN_TIMES
      );
      const businessInfoFeatureShownTimes = storedValueBI
        ? JSON.parse(storedValueBI)
        : [];

      const storedValueEL = localStorage.getItem(
        EMPLOYEE_LOGIN_FEATURE_ANNOUNCEMENT_SHOWN_TIMES
      );
      const employeeLoginFeatureShownTimes = storedValueEL
        ? JSON.parse(storedValueEL)
        : [];

      const maxShownTime = Math.max(
        appMonitoringFeatureShownTimes[
        appMonitoringFeatureShownTimes.length - 1
        ] || 0,
        businessInfoFeatureShownTimes[businessInfoFeatureShownTimes.length - 1] ||
        0,
        employeeLoginFeatureShownTimes[
        employeeLoginFeatureShownTimes.length - 1
        ] || 0
      );
      const featureShownToday = isToday(new Date(maxShownTime));

      // App monitoring feature

      const locationAppMonitoringIsTurnedOffByDefault =
        customerSupportConfigurations?.customerFeatureEnablement
          .applicationMonitoringPrivacyPromptEnabled;
      const locationAppMonitoringIsTurnedOff = !shieldSettings.data.appTime;
      const deviceAppMonitoringIsTurnedOn =
        !locationSecurityAppliesToAllDevices.data.appTime;

      const needToShowAppMonitoringFeature =
        locationAppMonitoringIsTurnedOffByDefault && // ie, consent is required
        locationAppMonitoringIsTurnedOff &&
        !deviceAppMonitoringIsTurnedOn &&
        appMonitoringFeatureShownTimes.length < 3 &&
        !featureShownToday;

      // Business info feature

      const businessInfoCanBeCollected =
        customerSupportConfigurations?.customerFeatureEnablement
          .businessInfoEnabled;

      const needToShowBusinessInfoFeature =
        isPureDevDomain() &&
        businessInfoCanBeCollected &&
        businessInfoFeatureShownTimes.length < 3 &&
        !featureShownToday;

      // Employee login feature

      const needToShowEmployeeLoginFeature =
        employeeLoginFeatureIsSupported &&
        !employeeLoginToggleIsEnabled &&
        employeeLoginFeatureShownTimes.length < 3 &&
        !featureShownToday;

      // Announce the next highest priority applicable feature
      const now = new Date().getTime();
      if (needToShowAppMonitoringFeature) {
        setWhichModalIsOpen('appMonitoring');
        localStorage.setItem(
          APP_MONITORING_FEATURE_ANNOUNCEMENT_SHOWN_TIMES,
          JSON.stringify([...appMonitoringFeatureShownTimes, now])
        );
      } else if (needToShowBusinessInfoFeature) {
        setWhichModalIsOpen('businessInfo');
        localStorage.setItem(
          BUSINESS_INFO_FEATURE_ANNOUNCEMENT_SHOWN_TIMES,
          JSON.stringify([...businessInfoFeatureShownTimes, now])
        );
      } else if (needToShowEmployeeLoginFeature) {
        setWhichModalIsOpen('employeeLogin');
        localStorage.setItem(
          EMPLOYEE_LOGIN_FEATURE_ANNOUNCEMENT_SHOWN_TIMES,
          JSON.stringify([...employeeLoginFeatureShownTimes, now])
        );
      }
    }
  }, [
    employeeLoginFeatureIsSupported,
    employeeLoginToggleIsEnabled,
    customerSupportConfigurations,
    locationSecurityAppliesToAllDevices,
    shieldSettings,
  ]);

  const activeLocation = useLocations();
  const [redirectToSSIDConfig, setRedirectToSSIDConfig] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (
      activeLocation.activeLocation.data &&
      !activeLocation.activeLocation.data?.ssid
    ) {
      setRedirectToSSIDConfig(true);
    }
  }, [activeLocation]);

  const handleSelectFilter = useCallback(
    (name: FilterNamePlusAll) => {
      setActiveFilter((prev) => {
        return prev === name ? 'all' : name;
      });
    },
    [setActiveFilter]
  );

  const handleShieldViewAllClick = useCallback(() => {
    navigate(ROUTES.shield);
  }, [navigate]);

  const shield = (
    <ShieldGraphCard
      isLoading={isLoading}
      errorMessage={errorMessage}
      eventsData={securityEvents || []}
      activeTypeFilter={activeFilter}
      onTypeFilterClick={handleSelectFilter}
      onViewAllClick={handleShieldViewAllClick}
    />
  );

  const guests = <Guests maxItems={5} />;

  const handleAddEmployee = useCallback(
    (ev: React.MouseEvent) => {
      dispatch(
        actions.ui.page.setSidepanelOpenTriggerType(
          ev.type as OpenTriggerEventType
        )
      );
      setContent(<AddEmployeeSidePanel />);
    },
    [setContent]
  );

  useEffect(() => {
    trackEvent({
      eventName: MixPanelEvents.SCREEN,
      additionalContent: {
        SCREEN: AvailableScreens.Home,
      },
    });
  }, []);

  const closeModal = () => {
    setWhichModalIsOpen(null);
  };

  const handleAppMonitoringCTAClick = () => {
    closeModal();
    dispatch(actions.locations.updateActiveLocationAppTime(true));
  };

  const handleBusinessInfoCTAClick = () => {
    closeModal();
    localStorage.setItem('settingsExpanded', true.toString());
    navigate(ROUTES.settings.businessInfo);
  };

  const handleEmployeeLoginCTAClick = () => {
    closeModal();
    localStorage.setItem('settingsExpanded', true.toString());
    navigate(ROUTES.settings.employee);
  };

  return redirectToSSIDConfig ? (
    <Navigate to={ROUTES.ssidConfiguration.configuration} replace />
  ) : (
    <Page>
      <Background ref={ref}>
        <PageHeader
          label={t('common.home')}
          buttonPillProps={
            (employeeLoginFeatureIsSupported && employeeLoginToggleIsEnabled)
              ? undefined
              : {
                label: t('common.addEmployee'),
                onClick: handleAddEmployee,
              }
          }
        />

        <Columns columnCount={columns} topMargin={16}>
          <EmployeesAtWork
            employeeLoginFeatureIsSupportedAndEnabled={
              (employeeLoginFeatureIsSupported && employeeLoginToggleIsEnabled)
            }
          />
          {shield}
          {guests}
          <WlanCard />
        </Columns>
      </Background>

      <Modal isOpen={whichModalIsOpen === 'appMonitoring'} onClose={closeModal}>
        <FeatureAnnouncement
          handleCancelAction={closeModal}
          handleCallToAction={handleAppMonitoringCTAClick}
          title={t('homepage.enableAppMonitoring')}
          paragraph={t('homepage.enableAppMonitoringDescription')}
          additionalInfo={t('homepage.enableAppMonitoringAdditionalInfo')}
          additionalInfoBottomPadding
          iconName={IconNames.AppMonitoringIllustration}
          callToActionLabel={t('common.enable')}
          cancelActionLabel={t('common.notNow')}
        />
      </Modal>

      <Modal isOpen={whichModalIsOpen === 'businessInfo'} onClose={closeModal}>
        <FeatureAnnouncement
          handleCancelAction={closeModal}
          handleCallToAction={handleBusinessInfoCTAClick}
          title={t('homepage.businessProfile')}
          paragraph={t('homepage.businessProfileDescription')}
          iconName={IconNames.KeycardIllustration}
          callToActionLabel={t('homepage.businessProfileCTA')}
          cancelActionLabel={t('common.notNow')}
        />
      </Modal>

      <Modal isOpen={whichModalIsOpen === 'employeeLogin'} onClose={closeModal}>
        <FeatureAnnouncement
          handleCancelAction={closeModal}
          handleCallToAction={handleEmployeeLoginCTAClick}
          title={t('homepage.newEmployeeLogin')}
          paragraph={t('homepage.newEmployeeLoginDescription')}
          iconName={IconNames.KeycardIllustration}
          callToActionLabel={t('common.open')}
          cancelActionLabel={t('common.notNow')}
        />
      </Modal>
    </Page>
  );
};

export default Home;
