import {
  APP_MONITORING_FEATURE_ANNOUNCEMENT_SHOWN_TIMES,
  BUSINESS_INFO_FEATURE_ANNOUNCEMENT_SHOWN_TIMES,
  EMAIL_REGEX,
  EMPLOYEE_LOGIN_FEATURE_ANNOUNCEMENT_SHOWN_TIMES,
} from 'Consts/defintions';
import { cloudLookupFromGlobalAuth } from 'Consts/environments';
import { ROUTES } from 'Consts/routes';
import capitalize from 'lodash/capitalize';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as actions from 'State/actions';
import * as selectors from 'State/selectors';
import { AppDispatch } from 'State/store';
import InputListItem from 'UI/Components/Lists/List input';
import Button, { BUTTON_THEMES } from 'UI/Elements/Button';
import { IconNames } from 'UI/Elements/Icon';
import { useTrackEvent } from '../../../trackingAnalytics/hooks/useTrackEvent';
import { MixPanelEvents } from '../../../trackingAnalytics/mixPanelEvents';
import { AvailableScreens } from '../../../trackingAnalytics/types';
import CloudSelector from '../Components/CloudSelector';
import {
  OnboardingPageBottomContent,
  OnboardingPageTopContent,
  OnboardingPageWrapper,
} from '../Components/Onboarding page wrapper';
import onboardingStyles from '../style.module.css';
import styles from './style.module.css';

const Login: FunctionComponent = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const authError = useSelector(selectors.auth.authError);

  const [email, setEmail] = useState(
    localStorage.getItem('customerEmail') || ''
  );
  const [password, setPassword] = useState('');
  const [isCloudMenuOpen, setIsCloudMenuOpen] = useState(false);
  const [error, setError] = useState({ password: '', email: '' });
  const trackEvent = useTrackEvent();
  const { data: globalAuthData } = useSelector(
    selectors.globalAuthLoginOptions
  );

  const cloudListFromGlobalAuth = globalAuthData?.legacyLoopbackAuth
    ?.filter((loopbackauth) => loopbackauth.loginType === 'UsernamePassword')
    .map((loopback) => cloudLookupFromGlobalAuth[loopback.deploymentName]);

  const handleChangeEmail = useCallback(
    (value: string) => {
      const trimmedEmail = value.trim();
      localStorage.setItem('customerEmail', trimmedEmail);
      setError((oldError) => ({ ...oldError, email: '' }));
      dispatch(actions.auth.clear());
      setEmail(trimmedEmail);
    },
    [dispatch]
  );

  const handleChangePassword = useCallback(
    (value: string) => {
      setError((oldError) => ({ ...oldError, password: '' }));
      dispatch(actions.auth.clear());
      setPassword(value);
    },
    [dispatch]
  );

  const handleForgotPassword = useCallback(() => {
    navigate(ROUTES.onboarding.resetPassword);
  }, [navigate]);

  const handleCloudMenuClose = useCallback(() => setIsCloudMenuOpen(false), []);
  const handleCloudMenuOpen = useCallback((ev: React.MouseEvent) => {
    setIsCloudMenuOpen(true);
  }, []);

  const handleMagicLinkClick = useCallback(() => {
    navigate(ROUTES.onboarding.magicLink);
  }, [navigate]);

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const updateFeatureAnnouncementOnLoginShownTimes = (
    localStorageValues: string[]
  ) => {
    localStorageValues.forEach((value) => {
      const currentLocalStorageValue = localStorage.getItem(value);

      if (!currentLocalStorageValue) {
        localStorage.setItem(value, '[]');
      }
    });
  };

  const handleLogin = useCallback(async () => {
    if (!email) {
      setError((prev) => ({ ...prev, email: t('onboarding.errorEmptyField') }));
      return;
    }

    if (!EMAIL_REGEX.test(email)) {
      setError(() => ({ ...error, email: t('onboarding.errorInvalidEmail') }));
      return;
    }

    if (!password) {
      setError((prev) => ({
        ...prev,
        password: t('onboarding.errorEmptyField'),
      }));
      return;
    }

    dispatch(actions.auth.login({ email, password }));
    updateFeatureAnnouncementOnLoginShownTimes([
      APP_MONITORING_FEATURE_ANNOUNCEMENT_SHOWN_TIMES,
      BUSINESS_INFO_FEATURE_ANNOUNCEMENT_SHOWN_TIMES,
      EMPLOYEE_LOGIN_FEATURE_ANNOUNCEMENT_SHOWN_TIMES,
    ]);
  }, [dispatch, email, password, error, t]);

  useEffect(() => {
    if (authError) {
      const errorMessage = capitalize(authError);

      setError({ email: errorMessage, password: '' });
    }
  }, [authError]);

  useEffect(() => {
    setError({ password: '', email: '' });
    trackEvent({
      eventName: MixPanelEvents.SCREEN,
      additionalContent: {
        SCREEN: AvailableScreens.LoginPage,
      },
    });
    if (cloudListFromGlobalAuth) {
      dispatch(actions.auth.setCloud(cloudListFromGlobalAuth[0]));
    }
  }, []);

  return (
    <OnboardingPageWrapper
      label={t('onboarding.signInLabel')}
      subLabel={t('onboarding.signInSubLabel')}
    >
      <OnboardingPageTopContent>
        <InputListItem
          className={onboardingStyles.stretch}
          prefixIcon={IconNames.EmailIcon}
          placeholder={t('onboarding.yourEmail')}
          smallLabel={email ? (t('onboarding.yourEmail') as string) : ''}
          paragraph={error.email ? error.email : ''}
          value={email}
          onChange={handleChangeEmail}
          onSubmit={handleLogin}
          hasError={Boolean(error.email)}
          disabled={Boolean(globalAuthData)}
        />

        <InputListItem
          className={onboardingStyles.stretch}
          inputType="password"
          prefixIcon={IconNames.Key}
          placeholder={t('onboarding.yourPassword')}
          smallLabel={password ? (t('onboarding.yourPassword') as string) : ''}
          value={password}
          onChange={handleChangePassword}
          onSubmit={handleLogin}
          paragraph={error.password ? error.password : ''}
          hasError={Boolean(error.password)}
        />

        {(!cloudListFromGlobalAuth || cloudListFromGlobalAuth?.length > 1) && (
          <CloudSelector
            isOpen={isCloudMenuOpen}
            onSelect={handleCloudMenuOpen}
            onClose={handleCloudMenuClose}
            onSubmit={handleLogin}
            enabledCloudList={cloudListFromGlobalAuth}
          />
        )}
      </OnboardingPageTopContent>

      <OnboardingPageBottomContent>
        <Button
          className={styles.forgotButton}
          theme={BUTTON_THEMES.noborder}
          label={t('onboarding.forgotPassword')}
          onClick={handleForgotPassword}
        />

        <Button
          theme={BUTTON_THEMES.white}
          label={t('onboarding.signInWithMagicLink')}
          prefixIcon={IconNames.MagicLink}
          onClick={handleMagicLinkClick}
        />

        <Button label={t('onboarding.signIn')} onClick={handleLogin} />

        <Button
          theme={BUTTON_THEMES.noborder}
          label={t('common.back')}
          onClick={handleBack}
        />
      </OnboardingPageBottomContent>
    </OnboardingPageWrapper>
  );
};

export default Login;
