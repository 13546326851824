import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { PERIODS } from 'Consts/defintions';

import {
  Categories,
  DataDailyToday,
  DataLast30Days,
  OpenTriggerEventType,
  Period,
} from 'Consts/types';

import CategoryDataUsageGraph from 'UI/Reusable/Data usage graph/Category';
import dataUsageGraphTooltip from 'UI/Reusable/Data usage graph/Components/Data usage graph tooltip';

import * as actions from 'State/actions';
import { useSidepanel } from 'Utils/hooks/useSidepanel';
import { displayBytes } from 'Utils/mbMath';

import DataUsagePlaceholderImg from 'Assets/Images/dataUsagePlaceholder.svg';

import {
  formatDailyStats,
  formatHourlyStats,
  generateUsageDataCategoryList,
  getCategoryGraphData,
  getDailyDataGraph,
  getDataAverageLabel,
  getHourlyDataGraph,
  mostUsedCategories,
} from '../../utils';

import { DataUsageColors } from 'Consts/defintions';

import { useDispatch } from 'react-redux';
import useSecurityPolicy from 'State/hooks/useSecurityPolicy';
import { AppDispatch } from 'State/store';
import ChartPlaceholder from 'UI/Components/ChartPlaceholder';
import DataUsageSidePanel from '../Data usage sidepanel';

type DataUsageUIProps = {
  daily: DataDailyToday | null;
  monthly: DataLast30Days | null;
  span?: 2 | 3;
};

const DataUsageGraph: FunctionComponent<DataUsageUIProps> = ({
  daily,
  monthly,
  span,
}) => {
  const { content, setContent } = useSidepanel();
  const { t } = useTranslation();

  const [period, setPeriod] = useState<Period>(PERIODS.day);
  const [displaySidePanel, setDisplaySidePanel] = useState(false);
  const { shieldSettings, locationSecurityAppliesToAllDevices } =
    useSecurityPolicy();

  const prevPeriod = useRef<Period>(period);

  const { hourlyStats, overallStats } = daily || {};
  const { dailyStats, monthlyStats } = monthly || {};
  const dispatch = useDispatch<AppDispatch>();

  const statsByHour = useMemo(
    () => formatHourlyStats(hourlyStats),
    [hourlyStats]
  );
  const statsByDay = useMemo(
    () => formatDailyStats(dailyStats, period),
    [dailyStats, period]
  );

  const sortedCategoryData = useMemo(() => {
    return generateUsageDataCategoryList(
      period === PERIODS.day ? statsByHour : statsByDay
    );
  }, [period, statsByDay, statsByHour]);

  const mostUsed = useMemo(
    () => mostUsedCategories(sortedCategoryData),
    [sortedCategoryData]
  );

  const itemsToShow = useMemo(() => {
    return mostUsed.reduce((acc, cur) => {
      return { ...acc, [cur.name]: 0 };
    }, {});
  }, [mostUsed]);

  const colors = useMemo(() => {
    return mostUsed.reduce((acc, cur, index) => {
      acc[cur.name] = DataUsageColors[index];

      return acc;
    }, {} as Record<Categories, string>);
  }, [mostUsed]);

  const graphData = useMemo(() => {
    if (period === PERIODS.day) {
      return getHourlyDataGraph(statsByHour, itemsToShow);
    }

    return getDailyDataGraph(statsByDay, itemsToShow, period);
  }, [itemsToShow, period, statsByDay, statsByHour]);

  const displayUsageSidePanel = (ev: React.MouseEvent) => {
    setDisplaySidePanel(true);
    handleUsageSidebar(ev);
  };

  const handleUsageSidebar = useCallback(
    (ev?: React.MouseEvent) => {
      ev &&
        dispatch(
          actions.ui.page.setSidepanelOpenTriggerType(
            ev.type as OpenTriggerEventType
          )
        );
      setContent(<DataUsageSidePanel statistics={sortedCategoryData} />);
    },
    [sortedCategoryData, setContent]
  );

  useEffect(() => {
    if (!content && displaySidePanel) {
      setDisplaySidePanel(false);
    }
    if (displaySidePanel && content && period !== prevPeriod.current) {
      handleUsageSidebar();

      prevPeriod.current = period;
    }
  }, [content, handleUsageSidebar, period]);

  const { data: displayGraphData, unit } = useMemo(() => {
    return getCategoryGraphData(graphData);
  }, [graphData]);

  const totalData = sortedCategoryData.reduce(
    (acc, [, number]) => acc + number,
    0
  );

  const secondLabel = totalData ? displayBytes(totalData) : t('common.noData');

  const { i18key, value } = getDataAverageLabel({
    period,
    dailyStats: statsByDay,
    dailyAverage: overallStats?.hourlyAvgTotalBytes,
    monthlyAverage: monthlyStats?.dailyAvgTotalBytes,
  });

  return shieldSettings.data.appTime ||
    (!shieldSettings.data.appTime &&
      !locationSecurityAppliesToAllDevices.data.appTime) ? (
    <CategoryDataUsageGraph
      span={span}
      label={t('common.dataUsage')}
      secondLabel={secondLabel}
      graphProps={{
        data: displayGraphData,
        customTooltip: dataUsageGraphTooltip(unit),
        colors,
        filters: mostUsed,
        period,
        setPeriod,
        averageLabel: t(i18key, { value }),
        onCategoryIconClick: displayUsageSidePanel,
      }}
    />
  ) : (
    <ChartPlaceholder
      backgroundImageUrl={DataUsagePlaceholderImg}
      height="319px"
      placeholderTitle={t('common.dataUsage')}
      placeholderSubtitle="8.93 GB"
    />
  );
};

export default DataUsageGraph;
